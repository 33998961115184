import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Outlet, useLocation } from "react-router";
import Nav from "./nav";

export default function PortalLayout() {
  return (
    <div className="portal-layout">
      <Container fluid>
        <Row>
          <Col lg={2}>
            <Nav />
          </Col>
          <Col>
            <main>
              <Outlet lg={10} />
            </main>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
