import { useState } from "react";
import { Button } from "react-bootstrap";
import commonService from "../../../../services/commonService";
import sembraNetService from "../../../../services/sembraNetService";
import UserMessage from "../../../common/userMessages";
import SortableTable from "../../../common/tables/sortableTable";
import rnData from "../../../../mockData/rnData";
import SearchRnAssignmentsForm from "../../../forms/protectedRouteForms/sembraNet/search-rn-assignemtns-form";
import RnTable from "../../../common/tables/sortableTables/rnListTable";
import AssignmentTable from "../../../common/tables/sortableTables/assignmentList";

export default function SearchRnAssignments() {
  const [userMessage, setUserMessage] = useState({});
  const handleUserMessage = (type, message, message2) => {
    setUserMessage({
      ...userMessage,
      type: type,
      message: message,
      message2: message2,
    });
  };

  const [responseData, setReponseData] = useState({});
  // console.log("Response Data: ", responseData);
  const handleFormikSubmit = async (values, actions) => {
    // let eventCreated = false;
    // try {
    //   await commonService.createEventLog({
    //     eventApplication: "Success",
    //     eventFamily: "SembraNet",
    //     eventType: "Search RN Assignment",
    //     eventUserFullName: "Add user name here after adding cognito",
    //     eventUsername: "Add user name here after adding cognito",
    //   });
    //   eventCreated = true;
    // } catch (e) {
    //   handleUserMessage(
    //     "danger",
    //     `Error: ${e.message}`,
    //     "If this continues, contact your systems administrator"
    //   );
    // }

    // if (eventCreated) {
    try {
      const data = await sembraNetService.searchRnAssignments(values);

      // console.log("Data: ", data);
      if (data.length === 0) {
        handleUserMessage("danger", `Error:  Unable to Locate RN`);
      } else {
        setReponseData(data);
      }
    } catch (e) {
      console.log("ERROR FETCHING DATA: ", e);
    }
  };
  // else {
  //   handleUserMessage(
  //     "danger",
  //     `Error: Unable to create event`,
  //     "If this continues, contact your systems administrator"
  //   );
  // }
  // };

  const [selectedRn, setSelectedRn] = useState(null);
  console.log("Selected RN", selectedRn);
  const handleSelectedRn = (selectedRow) => {
    setSelectedRn(selectedRow);
  };

  // const [selectedAssignment, setSelectedAssignment] = useState(null);
  // console.log("Selected Assignment", selectedAssignment);
  // const handleSelectedAssignment = (selectedRow) => {
  //   setSelectedAssignment(selectedRow);
  // };

  const handleNewRnSearch = () => {
    setReponseData({});
    setUserMessage({});
    setSelectedRn(null);
  };

  return (
    <section className="selected-option-content">
      <h3>Search RN Assignments</h3>
      {Object.keys(responseData).length === 0 ? (
        <div>
          <SearchRnAssignmentsForm
            handleFormikSubmit={handleFormikSubmit}
            handleUserMessage={handleUserMessage}
          />
        </div>
      ) : (
        <div>
          {selectedRn ? (
            <>
              <h4 style={{ textAlign: "center" }}>Assignment List</h4>

              {/* <SortableTable
                type="rn-assignments"
                // handleSelectedAssignment={handleSelectedAssignment}
                tableData={selectedRn}
              /> */}
              <AssignmentTable tableData={selectedRn} />
              <Button className="mb-5" onClick={() => handleNewRnSearch()}>
                Back to RN Search
              </Button>
            </>
          ) : (
            <>
              <h4 style={{ textAlign: "center" }}>RN List</h4>
              <RnTable
                tableData={responseData}
                handleSelectedRn={handleSelectedRn}
              />
              {/* <SortableTable
                type="rn-list"
                handleSelectedRn={handleSelectedRn}
                tableData={responseData}
                handleUserMessage={handleUserMessage}
                // handleSelectedAssignment={handleSelectedAssignment}
              /> */}
              <Button className="mb-5" onClick={() => handleNewRnSearch()}>
                Back to RN Search
              </Button>
            </>
          )}
        </div>
      )}
      <UserMessage userMessage={userMessage} />
    </section>
    // <section className="selected-option-content">
    //   <h3>Search RN Assignments</h3>
    //   {Object.keys(responseData).length > 0 ? (
    //     <div>
    //       <SortableTable
    //         type="rn-list"
    //         handleSelectedRn={handleSelectedRn}
    //         tableData={responseData}
    //         handleUserMessage={handleUserMessage}
    //       />
    //       <Button className="mb-5" onClick={() => handleNewRnSearch()}>
    //         Back to RN Search
    //       </Button>
    //     </div>
    //   ) : (
    //     <div>
    //       <SearchRnAssignmentsForm
    //         handleFormikSubmit={handleFormikSubmit}
    //         handleUserMessage={handleUserMessage}
    //       />
    //     </div>
    //   )}
    // </section>

    // <section className="selected-option-content">
    //   <h3>Search RN Assignments</h3>
    //   {!selectedRn ? (
    //     <SortableTable
    //       type="rn-list"
    //       handleSelectedRn={handleSelectedRn}
    //       tableData={rnData}
    //       handleUserMessage={handleUserMessage}
    //     />
    //   ) : (
    //     <>
    //       <SortableTable
    //         type="rn-assignments"
    //         handleSelectedRn={handleSelectedRn}
    //         tableData={selectedRn}
    //       />
    //       <Button className="mb-5" onClick={() => handleNewRnSearch()}>
    //         Back to RN Search
    //       </Button>
    //     </>
    //   )}
    // </section>
  );
}
