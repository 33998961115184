import { useState } from "react";
import { Button, Container, Col, Row, Table } from "react-bootstrap";
import UserMessage from "../../../common/userMessages";
import ResponseTable from "../../../common/tables/responseTable";
import SearchSembratimeShiftForm from "../../../forms/protectedRouteForms/sembraTime/search-shift-form";
import sembraTimeService from "../../../../services/sembraTimeService";

export default function SearchSembraTimeShift() {
  const [userMessage, setUserMessage] = useState({});
  const handleUserMessage = (type, message, message2) => {
    setUserMessage({
      ...userMessage,
      type: type,
      message: message,
      message2: message2,
    });
  };

  const [responseData, setResponseData] = useState({});
  const handleFormikSubmit = async (values, actions) => {
    // console.log("VAULUES: ", values, "\n", "ACTIONS: ", actions);
    setUserMessage({});
    try {
      const { data } = await sembraTimeService.searchShift(values);

      // console.log(data);

      if (data.status === "Success") {
        getDeviations(data.userInfo.deviations);
        delete data.userInfo.deviations;
        setResponseData(data.userInfo);
        handleUserMessage(
          "success",
          `Shift Info for ${data.userInfo.serviceDate}`
        );
      } else {
        handleUserMessage("danger", data.message);
      }
    } catch (e) {
      handleUserMessage(
        "danger",
        `ERROR: ${e.message}`,
        "If this continues, contact your systems administrator"
      );
    }
  };

  const [deviationList, setDeviationList] = useState([]);
  const getDeviations = (data) => {
    let deviationItems = [];
    let deviationObj = {
      "Check In Location Deviation": data.checkInLocationDeviation,
      "Check In Time Deviation": data.checkInTimeDeviation,
      "Check Out Location Deviation": data.checkOutLocationDeviation,
      "Check Out Time Devaition": data.checkOutTimeDeviation,
    };

    for (const key in deviationObj) {
      if (
        deviationObj.hasOwnProperty(key) &&
        typeof deviationObj[key] === "string" &&
        deviationObj[key].trim() !== ""
      ) {
        deviationItems.push(`${key}: ${deviationObj[key]}`);
      }
    }
    setDeviationList(deviationItems);
  };

  const handleNewShift = () => {
    setResponseData({});
    setUserMessage({});
  };

  return (
    <section className="selected-option-content">
      <h3>Search SembraTime Shift</h3>
      {Object.values(responseData).length > 0 ? (
        <>
          <UserMessage userMessage={userMessage} />
          <ResponseTable
            responseData={responseData}
            deviationList={deviationList}
          />
          <Button onClick={() => handleNewShift()}>New Search</Button>
        </>
      ) : (
        <>
          <SearchSembratimeShiftForm
            handleFormikSubmit={handleFormikSubmit}
            handleUserMessage={handleUserMessage}
          />
          <UserMessage userMessage={userMessage} />
        </>
      )}
    </section>
  );
}
