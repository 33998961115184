export const formatDate = (date_time) => {
  const date = new Date(date_time);
  // adjust 0 before single digit date
  let day = ("0" + date.getDate()).slice(-2);

  // get current month
  let month = ("0" + (date.getMonth() + 1)).slice(-2);

  // get current year
  let year = date.getFullYear();

  // get current hours
  let hours = date.getHours();

  // get current minutes
  let minutes = date.getMinutes();

  // get current seconds
  let seconds = date.getSeconds();

  // prints date in YYYY-MM-DD format
  const formattedDate = `${month}-${day}-${year} ${hours}:${minutes}:${seconds}`;
  return formattedDate;
};