const rnData = [
  {
    id: 1,
    lastName: "Darling",
    firstName: "Wendy",
    mobilePhone: "111",
    assignments: [
      {
        id: 1,
        date: "date here",
        time: "time here",
        clientLastName: "Mike",
        clientFirstName: "Magic",
      },
      {
        id: 2,
        date: "date here",
        time: "time here",
        clientLastName: "Cars",
        clientFirstName: "Leith",
      },
    ],
  },
  {
    id: 2,
    lastName: "Darling",
    firstName: "John",
    mobilePhone: "2222",
    assignments: [
      {
        id: 1,
        date: "date here",
        time: "time here",
        clientLastName: "Baggins",
        clientFirstName: "Bilbo",
      },
      {
        id: 2,
        date: "date here",
        time: "time here",
        clientLastName: "Whitethorn",
        clientFirstName: "Rowan",
      },
    ],
  },
  {
    id: 3,
    lastName: "Darling",
    firstName: "Michael",
    mobilePhone: "333",
    assignments: [
      {
        id: 1,
        date: "date here",
        time: "time here",
        clientLastName: "Groot",
        clientFirstName: "I Am",
      },
      {
        id: 2,
        date: "date here",
        time: "time here",
        clientLastName: "Deus",
        clientFirstName: "Homo",
      },
    ],
  },
  {
    id: 4,
    lastName: "Darling",
    firstName: "Maxwell",
    mobilePhone: "444",
    assignments: [
      {
        id: 1,
        date: "date here",
        time: "time here",
        clientLastName: "Granger",
        clientFirstName: "Hermione",
      },
      {
        id: 2,
        date: "date here",
        time: "time here",
        clientLastName: "Potter",
        clientFirstName: "Harry",
      },
    ],
  },
  {
    id: 5,
    lastName: "Pan",
    firstName: "Peter",
    mobilePhone: "555",
    assignments: [
      {
        id: 1,
        date: "date here",
        time: "time here",
        clientLastName: "Bennet",
        clientFirstName: "Elizabeth",
      },
      {
        id: 2,
        date: "date here",
        time: "time here",
        clientLastName: "Dolittle",
        clientFirstName: "Dr",
      },
    ],
  },
  {
    id: 6,
    lastName: "Pan",
    firstName: "Piper",
    mobilePhone: "777",
    assignments: [
      {
        id: 1,
        date: "date here",
        time: "time here",
        clientLastName: "Finch",
        clientFirstName: "Atticus",
      },
      {
        id: 2,
        date: "date here",
        time: "time here",
        clientLastName: "Bear",
        clientFirstName: "Paddington",
      },
    ],
  },
  {
    id: 7,
    lastName: "Smith",
    firstName: "John",
    mobilePhone: "888",
    assignments: [],
  },
  {
    id: 8,
    lastName: "Smith",
    firstName: "Jack",
    mobilePhone: "999",
    assignments: [],
  },
  {
    id: 9,
    lastName: "Smith",
    firstName: "Emily",
    mobilePhone: "1010",
    assignments: [],
  },
  {
    id: 10,
    lastName: "Smith",
    firstName: "Erin",
    mobilePhone: "1111",
    assignments: [],
  },
];

export default rnData;
