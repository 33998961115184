import http from "./httpService";

function login(username, password) {
  const loginURL = "https://usermgmt.mysembra.com/aws-auth/login";
  const cognitoUser = {
    Username: username.toLowerCase(),
    Password: password,
    realm: "SembraSuccess"
  }
  try {
    return http.post(loginURL, cognitoUser).then(async res => {
      localStorage.setItem('success', username.toLowerCase());
      localStorage.setItem('token', res.data.idToken);
      // localStorage.setItem('userEmail', res.data.idToken.payload.email);
      // localStorage.setItem('userJwtToken', res.data.idToken.jwtToken);
      localStorage.setItem('userAccessToken', res.data.accessToken);
      localStorage.setItem('userRefreshToken', res.data.refreshToken);
      return res.data;
    }).catch(async err => {
      return err.response;
    })
  }
  catch {
    return "Error in Sending";
  };
}

export function logout() {
  localStorage.clear();
  window.location = "/login"
}

export function getCurrentUser() {
  try {
    return localStorage.getItem("success");
  } catch (ex) {
    return null;
  }
}

export default {
  login,
  logout,
  getCurrentUser
}