import { Button } from "react-bootstrap";

export default function SubmitBtn(props) {
  return (
    <div className="d-flex align-items-center">
      <Button
        variant="primary" // Default
        disabled={props.isSubmitting ? true : false}
        type="submit"
      >
        {props.text}
      </Button>
      <div
        className={`ms-3 submit-loader ${props.isSubmitting ? "show" : "hide"}`}
      ></div>
    </div>
  );
}
