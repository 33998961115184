// import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate, useLocation, Link } from "react-router-dom";

export default function SembraCareOptions() {
  const navigate = useNavigate();
  const location = useLocation();

  const handleSelection = (selection) => {
    navigate(`${selection}`);
  };

  const getActiveSelection = (selection) => {
    if (location.pathname.includes(selection)) {
      return "active";
    }
  };

  return (
    <div className="dashboard-options">
      <Button
        variant=""
        className={getActiveSelection("sembraCare/badge-alterations")}
        onClick={() => handleSelection("badge-alterations")}
      >
        Badge Alterations
      </Button>
      <Button
        variant=""
        className={getActiveSelection("sembraCare/address-coordinates")}
        onClick={() => handleSelection("address-coordinates")}
      >
        Address Coordinates
      </Button>
    </div>
  );
}
