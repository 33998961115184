import React, { useEffect } from "react";
import ResetPasswordForm from "../../forms/publicRouteForms/reset-passwordForm";
import { Link } from "react-router-dom";

export default function ResetPassword({}) {
  useEffect(() => {}, []);

  const handleFormikSubmit = (values, actions) => {
    console.log(
      "HANDLING SUBMIT",
      "\n",
      "VALUES:",
      values,
      "\n",
      "ACTIONS: ",
      actions
    );
    // Login/Submit Button will be disabled while submitting
    // actions.setSubmitting(true);
  };

  return (
    <main className="registration">
      <section className="main-section">
        <h2>Reset Password</h2>
        <section className="main-section-content">
          <ResetPasswordForm handleFormikSubmit={handleFormikSubmit} />
          <hr />
          <section className="text-center mt-3">
            <div className="d-flex justify-content-around">
              {/* <p>Already have an account?</p> */}
              <Link to="/">LOGIN</Link>
            </div>
          </section>
        </section>
      </section>
    </main>
  );
}
