function setHeader() {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  };
  // console.log("CONFIG: ", config)
  return config;
}

export default {
  setHeader,
};
