export const GlobalFilter = ({ filter, setFilter }) => {
    return (
      <div>
        <span>Search: </span>
        <input
          type="text"
          value={filter || ""}
          onChange={(e) => setFilter(e.target.value)}
        />
      </div>
    );
  };
  