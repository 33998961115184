import { Form, InputGroup, Alert } from "react-bootstrap";
import SubmitBtn from "../../../common/submitBtn";
import { Formik } from "formik";
import { object, string } from "yup";
import { AiOutlineUser } from "react-icons/ai";
import {
  AiOutlineExclamationCircle,
  AiOutlineClockCircle,
} from "react-icons/ai";
import { MdOutlineNumbers, MdDateRange } from "react-icons/md";

const ChangeCheckinTimeForm = (props) => {
  const validationSchema = object().shape({
    username: string().required("Username is required"),
    cliIVRID: string().required("IVRID is required"),
    shiftDate: string().required("Enter a shift date"),
    updatedTime: string().required("Enter a shift time"),
  });

  const resetMessages = () => {
    props.handleUserMessage(null);
  };

  return (
    <section>
      <Formik
        initialValues={{
          username: "",
          cliIVRID: "",
          shiftDate: "",
          updatedTime: ""
        }}
        validationSchema={validationSchema}
        // validate={(values) => handleValidatations(values)}
        onSubmit={(values, actions) =>
          props.handleFormikSubmit(values, actions)
        }
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form className="mt-3" onSubmit={handleSubmit}>
            {/* {console.log("Values:", values)} */}
            <Form.Group className="mb-3 text-start" controlId="username">
              <Form.Label>SembraTime User</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <AiOutlineUser />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Agency Code + Employee IVRID (ex :hch100137)"
                  name="username"
                  onChange={(e) => {
                    handleChange(e);
                    resetMessages();
                  }}
                  value={values.username}
                  className={
                    errors.username && touched.username ? "input-error" : null
                  }
                />
                {errors.username && touched.username && (
                  <InputGroup.Text
                    className={errors.username ? "error-icon" : null}
                  >
                    <AiOutlineExclamationCircle />
                  </InputGroup.Text>
                )}
              </InputGroup>
              {errors.username && touched.username ? (
                <Alert variant="danger">{errors.username}</Alert>
              ) : null}
            </Form.Group>

            <Form.Group className="mb-3 text-start" controlId="cliIVRID">
              <Form.Label>Client IVRID</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <MdOutlineNumbers />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="example: 100213"
                  name="cliIVRID"
                  onChange={(e) => {
                    handleChange(e);
                    resetMessages();
                  }}
                  value={values.cliIVRID}
                  className={
                    errors.cliIVRID && touched.cliIVRID ? "input-error" : null
                  }
                />
                {errors.cliIVRID && touched.cliIVRID && (
                  <InputGroup.Text
                    className={errors.cliIVRID ? "error-icon" : null}
                  >
                    <AiOutlineExclamationCircle />
                  </InputGroup.Text>
                )}
              </InputGroup>
              {errors.cliIVRID && touched.cliIVRID ? (
                <Alert variant="danger">{errors.cliIVRID}</Alert>
              ) : null}
            </Form.Group>

            <Form.Group className="mb-3 text-start" controlId="shfitDate">
              <Form.Label>Shift Date</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <MdDateRange />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Select Shift Date"
                  name="shiftDate"
                  onChange={(e) => {
                    handleChange(e);
                    resetMessages();
                  }}
                  value={values.shiftDate}
                  className={
                    errors.shiftDate && touched.shiftDate ? "input-error" : null
                  }
                />
                {errors.shiftDate && touched.shiftDate && (
                  <InputGroup.Text
                    className={errors.shiftDate ? "error-icon" : null}
                  >
                    <AiOutlineExclamationCircle />
                  </InputGroup.Text>
                )}
              </InputGroup>
              {errors.shiftDate && touched.shiftDate ? (
                <Alert variant="danger">{errors.shiftDate}</Alert>
              ) : null}
            </Form.Group>

            <Form.Group className="mb-3 text-start" controlId="updatedTime">
              <Form.Label>Updated Check-In Time</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <AiOutlineClockCircle />
                </InputGroup.Text>
                <Form.Select
                  placeholder="Select Shift Time"
                  name="updatedTime"
                  onChange={(e) => {
                    handleChange(e);
                    resetMessages();
                  }}
                  value={values.updatedTime}
                  className={
                    errors.updatedTime && touched.updatedTime
                      ? "input-error"
                      : null
                  }
                >
                  <option value="06:00 AM">06:00 AM</option>
                  <option value="06:15 AM">06:15 AM</option>
                  <option value="06:30 AM">06:30 AM</option>
                  <option value="06:45 AM">06:45 AM</option>
                  <option value="07:00 AM">07:00 AM</option>
                  <option value="07:15 AM">07:15 AM</option>
                  <option value="07:30 AM">07:30 AM</option>
                  <option value="07:45 AM">07:45 AM</option>
                  <option value="08:00 AM">08:00 AM</option>
                  <option value="08:15 AM">08:15 AM</option>
                  <option value="08:30 AM">08:30 AM</option>
                  <option value="08:45 AM">08:45 AM</option>
                  <option value="09:00 AM">09:00 AM</option>
                  <option value="09:15 AM">09:15 AM</option>
                  <option value="09:30 AM">09:30 AM</option>
                  <option value="09:45 AM">09:45 AM</option>
                  <option value="10:00 AM">10:00 AM</option>
                  <option value="10:15 AM">10:15 AM</option>
                  <option value="10:30 AM">10:30 AM</option>
                  <option value="10:45 AM">10:45 AM</option>
                  <option value="11:00 AM">11:00 AM</option>
                  <option value="11:15 AM">11:15 AM</option>
                  <option value="11:30 AM">11:30 AM</option>
                  <option value="11:45 AM">11:45 AM</option>
                  <option value="12:00 PM">12:00 PM</option>
                  <option value="12:15 PM">12:15 PM</option>
                  <option value="12:30 PM">12:30 PM</option>
                  <option value="12:45 PM">12:45 PM</option>
                  <option value="01:00 PM">01:00 PM</option>
                  <option value="01:15 PM">01:15 PM</option>
                  <option value="01:30 PM">01:30 PM</option>
                  <option value="01:45 PM">01:45 PM</option>
                  <option value="02:00 PM">02:00 PM</option>
                  <option value="02:15 PM">02:15 PM</option>
                  <option value="02:30 PM">02:30 PM</option>
                  <option value="02:45 PM">02:45 PM</option>
                  <option value="03:00 PM">03:00 PM</option>
                  <option value="03:15 PM">03:15 PM</option>
                  <option value="03:30 PM">03:30 PM</option>
                  <option value="03:45 PM">03:45 PM</option>
                  <option value="04:00 PM">04:00 PM</option>
                  <option value="04:15 PM">04:15 PM</option>
                  <option value="04:30 PM">04:30 PM</option>
                  <option value="04:45 PM">04:45 PM</option>
                  <option value="05:00 PM">05:00 PM</option>
                  <option value="05:15 PM">05:15 PM</option>
                  <option value="05:30 PM">05:30 PM</option>
                  <option value="05:45 PM">05:45 PM</option>
                  <option value="06:00 PM">06:00 PM</option>
                  <option value="06:15 PM">06:15 PM</option>
                  <option value="06:30 PM">06:30 PM</option>
                  <option value="06:45 PM">06:45 PM</option>
                  <option value="07:00 PM">07:00 PM</option>
                  <option value="07:15 PM">07:15 PM</option>
                  <option value="07:30 PM">07:30 PM</option>
                  <option value="07:45 PM">07:45 PM</option>
                  <option value="08:00 PM">08:00 PM</option>
                  <option value="08:15 PM">08:15 PM</option>
                  <option value="08:30 PM">08:30 PM</option>
                  <option value="08:45 PM">08:45 PM</option>
                  <option value="09:00 PM">09:00 PM</option>
                  <option value="09:15 PM">09:15 PM</option>
                  <option value="09:30 PM">09:30 PM</option>
                  <option value="09:45 PM">09:45 PM</option>
                  <option value="10:00 PM">10:00 PM</option>
                  <option value="10:15 PM">10:15 PM</option>
                  <option value="10:30 PM">10:30 PM</option>
                  <option value="10:45 PM">10:45 PM</option>
                  <option value="11:00 PM">11:00 PM</option>
                  <option value="11:15 PM">11:15 PM</option>
                  <option value="11:30 PM">11:30 PM</option>
                  <option value="11:45 PM">11:45 PM</option>
                  <option value="12:00 AM">12:00 AM</option>
                  <option value="12:15 AM">12:15 AM</option>
                  <option value="12:30 AM">12:30 AM</option>
                  <option value="12:45 AM">12:45 AM</option>
                  <option value="01:00 AM">01:00 AM</option>
                  <option value="01:15 AM">01:15 AM</option>
                  <option value="01:30 AM">01:30 AM</option>
                  <option value="01:45 AM">01:45 AM</option>
                  <option value="02:00 AM">02:00 AM</option>
                  <option value="02:15 AM">02:15 AM</option>
                  <option value="02:30 AM">02:30 AM</option>
                  <option value="02:45 AM">02:45 AM</option>
                  <option value="03:00 AM">03:00 AM</option>
                  <option value="03:15 AM">03:15 AM</option>
                  <option value="03:30 AM">03:30 AM</option>
                  <option value="03:45 AM">03:45 AM</option>
                  <option value="04:00 AM">04:00 AM</option>
                  <option value="04:15 AM">04:15 AM</option>
                  <option value="04:30 AM">04:30 AM</option>
                  <option value="04:45 AM">04:45 AM</option>
                  <option value="05:00 AM">05:00 AM</option>
                  <option value="05:15 AM">05:15 AM</option>
                  <option value="05:30 AM">05:30 AM</option>
                  <option value="05:45 AM">05:45 AM</option>
                  <option value="06:00 AM">06:00 AM</option>
                  <option value="06:15 AM">06:15 AM</option>
                  <option value="06:30 AM">06:30 AM</option>
                  <option value="06:45 AM">06:45 AM</option>
                  <option value="07:00 AM">07:00 AM</option>
                  <option value="07:15 AM">07:15 AM</option>
                  <option value="07:30 AM">07:30 AM</option>
                  <option value="07:45 AM">07:45 AM</option>
                  <option value="08:00 AM">08:00 AM</option>
                  <option value="08:15 AM">08:15 AM</option>
                  <option value="08:30 AM">08:30 AM</option>
                  <option value="08:45 AM">08:45 AM</option>
                  <option value="09:00 AM">09:00 AM</option>
                  <option value="09:15 AM">09:15 AM</option>
                  <option value="09:30 AM">09:30 AM</option>
                  <option value="09:45 AM">09:45 AM</option>
                  <option value="10:00 AM">10:00 AM</option>
                  <option value="10:15 AM">10:15 AM</option>
                  <option value="10:30 AM">10:30 AM</option>
                  <option value="10:45 AM">10:45 AM</option>
                  <option value="11:00 AM">11:00 AM</option>
                  <option value="11:15 AM">11:15 AM</option>
                  <option value="11:30 AM">11:30 AM</option>
                  <option value="11:45 AM">11:45 AM</option>
                  <option value="12:00 PM">12:00 PM</option>
                  <option value="12:15 PM">12:15 PM</option>
                  <option value="12:30 PM">12:30 PM</option>
                  <option value="12:45 PM">12:45 PM</option>
                  <option value="01:00 PM">01:00 PM</option>
                  <option value="01:15 PM">01:15 PM</option>
                  <option value="01:30 PM">01:30 PM</option>
                  <option value="01:45 PM">01:45 PM</option>
                  <option value="02:00 PM">02:00 PM</option>
                  <option value="02:15 PM">02:15 PM</option>
                  <option value="02:30 PM">02:30 PM</option>
                  <option value="02:45 PM">02:45 PM</option>
                  <option value="03:00 PM">03:00 PM</option>
                  <option value="03:15 PM">03:15 PM</option>
                  <option value="03:30 PM">03:30 PM</option>
                  <option value="03:45 PM">03:45 PM</option>
                  <option value="04:00 PM">04:00 PM</option>
                  <option value="04:15 PM">04:15 PM</option>
                  <option value="04:30 PM">04:30 PM</option>
                  <option value="04:45 PM">04:45 PM</option>
                  <option value="05:00 PM">05:00 PM</option>
                  <option value="05:15 PM">05:15 PM</option>
                  <option value="05:30 PM">05:30 PM</option>
                  <option value="05:45 PM">05:45 PM</option>
                  <option value="06:00 PM">06:00 PM</option>
                  <option value="06:15 PM">06:15 PM</option>
                  <option value="06:30 PM">06:30 PM</option>
                  <option value="06:45 PM">06:45 PM</option>
                  <option value="07:00 PM">07:00 PM</option>
                  <option value="07:15 PM">07:15 PM</option>
                  <option value="07:30 PM">07:30 PM</option>
                  <option value="07:45 PM">07:45 PM</option>
                  <option value="08:00 PM">08:00 PM</option>
                  <option value="08:15 PM">08:15 PM</option>
                  <option value="08:30 PM">08:30 PM</option>
                  <option value="08:45 PM">08:45 PM</option>
                  <option value="09:00 PM">09:00 PM</option>
                  <option value="09:15 PM">09:15 PM</option>
                  <option value="09:30 PM">09:30 PM</option>
                  <option value="09:45 PM">09:45 PM</option>
                  <option value="10:00 PM">10:00 PM</option>
                  <option value="10:15 PM">10:15 PM</option>
                  <option value="10:30 PM">10:30 PM</option>
                  <option value="10:45 PM">10:45 PM</option>
                  <option value="11:00 PM">11:00 PM</option>
                  <option value="11:15 PM">11:15 PM</option>
                  <option value="11:30 PM">11:30 PM</option>
                  <option value="11:45 PM">11:45 PM</option>
                  <option value="12:00 AM">12:00 AM</option>
                  <option value="12:15 AM">12:15 AM</option>
                  <option value="12:30 AM">12:30 AM</option>
                  <option value="12:45 AM">12:45 AM</option>
                  <option value="01:00 AM">01:00 AM</option>
                  <option value="01:15 AM">01:15 AM</option>
                  <option value="01:30 AM">01:30 AM</option>
                  <option value="01:45 AM">01:45 AM</option>
                  <option value="02:00 AM">02:00 AM</option>
                  <option value="02:15 AM">02:15 AM</option>
                  <option value="02:30 AM">02:30 AM</option>
                  <option value="02:45 AM">02:45 AM</option>
                  <option value="03:00 AM">03:00 AM</option>
                  <option value="03:15 AM">03:15 AM</option>
                  <option value="03:30 AM">03:30 AM</option>
                  <option value="03:45 AM">03:45 AM</option>
                  <option value="04:00 AM">04:00 AM</option>
                  <option value="04:15 AM">04:15 AM</option>
                  <option value="04:30 AM">04:30 AM</option>
                  <option value="04:45 AM">04:45 AM</option>
                  <option value="05:00 AM">05:00 AM</option>
                  <option value="05:15 AM">05:15 AM</option>
                  <option value="05:30 AM">05:30 AM</option>
                  <option value="05:45 AM">05:45 AM</option>
                </Form.Select>
                {errors.updatedTime && touched.updatedTime && (
                  <InputGroup.Text
                    className={errors.updatedTime ? "error-icon" : null}
                  >
                    <AiOutlineExclamationCircle />
                  </InputGroup.Text>
                )}
              </InputGroup>
              {errors.updatedTime && touched.updatedTime ? (
                <Alert variant="danger">{errors.updatedTime}</Alert>
              ) : null}
            </Form.Group>

            <SubmitBtn
              isSubmitting={isSubmitting}
              text={"Update Check-In Time"}
            />
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default ChangeCheckinTimeForm;
