import { useState } from "react";
import { Form, InputGroup, Alert } from "react-bootstrap";
import SubmitBtn from "../../common/submitBtn";
import { Formik } from "formik";
import { object, string } from "yup";
import { AiOutlineUser } from "react-icons/ai";
import { RiLockPasswordFill } from "react-icons/ri";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import sembraSchoolService from "../../../services/sembraSchoolService";

const ResetUserPasswordForm = (props) => {
  const [EVVPassword, setEVVPassword] = useState();
  const validationSchema = object().shape({
    username: string().required("Username is required"),
    newPassword: string().required("Select a permission"),
  });

  const handleValidatations = (values) => {
    if (!values) {
      props.handleValidatations({});
    }
  };

  const resetMessages = (inputValue) => {
    props.handleUserMessage(null);
  };

  const handleEVVPassword = async (username) => {
    try {
      const { data } = await sembraSchoolService.pullAgencyGroups(username);
      if (data.length > 0 && username.length >= 0) {
        setEVVPassword(data[0].agencyEVVPassword);
      } else {
        setEVVPassword("");
      }
    } catch (e) {
      props.handleUserMessage("Error Pulling EVV Password");
      setEVVPassword("");
    }
  };

  return (
    <section>
      <Formik
        initialValues={{ username: "", newPassword: "" }}
        validationSchema={validationSchema}
        validate={(values) => handleValidatations(values)}
        onSubmit={(values, actions) =>
          props.handleFormikSubmit(values, actions)
        }
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form className="mt-3" onSubmit={handleSubmit}>
            {/* {console.log("Values:", values)} */}
            <Form.Group className="mb-3 text-start" controlId="username">
              <Form.Label>SembraTime/SembraSchool User</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <AiOutlineUser />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Agency Code + Employee IVRID (ex :hch100137)"
                  name="username"
                  onChange={(e) => {
                    handleChange(e);
                    resetMessages({});
                    handleEVVPassword(e.target.value);
                  }}
                  // onChange={handleChange}
                  value={values.username}
                  className={
                    errors.username && touched.username ? "input-error" : null
                  }
                />
                {errors.username && touched.username && (
                  <InputGroup.Text
                    className={errors.username ? "error-icon" : null}
                  >
                    <AiOutlineExclamationCircle />
                  </InputGroup.Text>
                )}
              </InputGroup>
              {errors.username && touched.username ? (
                <Alert variant="danger">{errors.username}</Alert>
              ) : null}
            </Form.Group>

            <Form.Group className="mb-3 text-start" controlId="newPassword">
              <Form.Label>New Password {EVVPassword ? " - Suggested: " + EVVPassword : ""}</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <RiLockPasswordFill />
                </InputGroup.Text>
                <Form.Control
                  type="newPassword"
                  placeholder="Enter New Password"
                  name="newPassword"
                  onChange={(e) => {
                    handleChange(e);
                    resetMessages({});
                  }}
                  value={values.newPassword}
                  className={
                    errors.newPassword && touched.newPassword
                      ? "input-error"
                      : null
                  }
                />
                {errors.newPassword && touched.newPassword && (
                  <InputGroup.Text
                    className={errors.newPassword ? "error-icon" : null}
                  >
                    <AiOutlineExclamationCircle />
                  </InputGroup.Text>
                )}
              </InputGroup>
              {errors.newPassword && touched.newPassword ? (
                <Alert variant="danger">{errors.newPassword}</Alert>
              ) : null}
            </Form.Group>

            <SubmitBtn
              isSubmitting={isSubmitting}
              text={"Update User Password"}
            />
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default ResetUserPasswordForm;
