import http from "./httpService";
import apiUrl from "../config.json";
import headerService from "./headerService";
import rnData from "../mockData/rnData";

async function searchRnAssignments(payload) {
  //   console.log("Search RN Asssignments Payload: ", payload);

  let matchingResults = [];
  const matchingObjects = rnData.filter(
    (obj) =>
      obj.lastName.toLowerCase() === payload.rnLastName.toLowerCase() &&
      obj.firstName.charAt(0).toLowerCase() ===
        payload.firstInitial.charAt(0).toLowerCase()
  );

  matchingResults = matchingObjects;

  if (matchingResults.length > 0) {
    return matchingResults;
  } else {
    return [];
  }
}


async function viewAassessmentStatus(payload) {
  console.log("View Assessemnt Payload: ", payload);
  const apiEndpoint = apiUrl.apiUrl + "/sembra-success/updateUserRole";
  //   const payload = {
  //     username: values.username,
  //     role: values.permission,
  //   };
  try {
    const response = await http.post(
      apiEndpoint,
      payload,
      headerService.setHeader()
    );
    return response;
  } catch (error) {
    throw error;
  }
}

export default {
  searchRnAssignments,
  viewAassessmentStatus,
};
