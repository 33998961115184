import http from "./httpService";
import apiUrl from "../config.json";
import headerService from "./headerService";

const config = {
  headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
};

async function searchShift(values) {
  const apiEndpoint = apiUrl.apiUrl + "/sembra-success/searchShift"
  const payload = {
    username: values.username.toLowerCase(),
    serviceDate: values.shiftDate,
    clientIVRID: values.cliIVRID
  }
  let date = new Date(values.shiftDate);
  let formattedDate = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) 
    + '-' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '-' + date.getFullYear();
  payload.serviceDate = formattedDate;
  try {
    const response = await http.post(apiEndpoint, payload, headerService.setHeader());
    return response;
  } catch (e) {
    throw e;
  }
}

async function resetShift(values) {
  const apiEndpoint = apiUrl.apiUrl + "/sembra-success/updateShift"

  const payload = {
    username: values.username.toLowerCase(),
    serviceDate: values.shiftDate,
    clientIVRID: values.cliIVRID,
    shiftActive: "PENDING"
  }
  let date = new Date(values.shiftDate);
  let formattedDate = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) 
    + '-' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '-' + date.getFullYear();
  payload.serviceDate = formattedDate;
  try {
    const response = await http.post(apiEndpoint, payload, headerService.setHeader());
    return response;
  } catch (e) {
    throw e;
  }
}

async function changeCheckinTime(values) {
  const apiEndpoint = apiUrl.apiUrl + "/sembra-success/updateShift"
  const payload = {
    username: values.username.toLowerCase(),
    clientIVRID: values.cliIVRID,
    serviceDate: values.shiftDate,
    startTime: values.updatedTime
  }
  let date = new Date(values.shiftDate);
  let formattedDate = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) 
    + '-' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '-' + date.getFullYear();
  payload.serviceDate = formattedDate;
  try {
    const response = await http.post(apiEndpoint, payload, headerService.setHeader());
    return response;
  } catch (e) {
    throw e;
  }
}

async function changeCheckinLocation(values) {
  const apiEndpoint = apiUrl.apiUrl + "/sembra-success/updateShift";
  const payload = {
    username: values.username.toLowerCase(),
    clientIVRID: values.cliIVRID,
    serviceDate: values.shiftDate,
    location: [values.lattitude, values.longitude]
  }
  let date = new Date(values.shiftDate);
  let formattedDate = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) 
    + '-' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '-' + date.getFullYear();
  payload.serviceDate = formattedDate;
  try {
    const response = await http.post(apiEndpoint, payload, headerService.setHeader());
    return response;
  } catch (e) {
    throw e;
  }
}

export default {
  searchShift,
  resetShift,
  changeCheckinTime,
  changeCheckinLocation
}