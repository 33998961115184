import React, { useState } from "react";
import { NavLink } from "react-router-dom";

export default function Nav() {
  return (
    <aside className="route-layout">
      <nav
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <NavLink className="mb-2" to="sembraTime">
          SembraTime
        </NavLink>
        <NavLink className="mb-2" to="sembraSchool">
          SembraSchool
        </NavLink>
        {/* <NavLink className="mb-2" to="sembraNet">
          SembraNet
        </NavLink> */}
        {/* <NavLink className="mb-2" to="sembraCare">
          SembraCare
        </NavLink> */}
      </nav>
    </aside>
  );
}
