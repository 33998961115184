export default function SembraNetAssessment() {
  return (
    <section className="selected-option-content">
      <h3>Assesment</h3>
      <div>
        <p>Additional Assessment content</p>
        <p>Additional Assessment content</p>
        <p>Additional Assessment content</p>
      </div>
    </section>
  );
}
