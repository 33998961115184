import React, { useState } from "react";
import { Outlet } from "react-router";
import Header from "./header";
import Nav from "./nav";
import Auth from "../common/Auth";

export default function RouteLayout() {
  return (
    <div className="route-layout" style={{ minHeight: "100vh" }}>
      <Header />
      {/* <main className="align-items-center"> */}
        <Outlet />
      {/* </main> */}
    </div>
  );
}
