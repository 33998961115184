import { useState } from "react";
import { Button } from "react-bootstrap";
import ChangeCheckinTimeForm from "../../../forms/protectedRouteForms/sembraTime/change-checkin-time-form";
import sembraTimeService from "../../../../services/sembraTimeService";
import commonService from "../../../../services/commonService";
import UserMessage from "../../../common/userMessages";
import ResponseTable from "../../../common/tables/responseTable";

export default function ChangeCheckinTime() {
  const [userMessage, setUserMessage] = useState({});
  const handleUserMessage = (type, message, message2) => {
    setUserMessage({
      ...userMessage,
      type: type,
      message: message,
      message2: message2,
    });
  };

  const [updatedData, setUpdatedData] = useState({});
  const handleFormikSubmit = async (values, actions) => {
    // console.log("VALUES: ", values, "\n", "ACTIONS: ", actions);
    let eventCreated = false;
    try {
      await commonService.createEventLog({
        eventApplication: "Success",
        eventFamily: "SembraTime",
        eventType: "Change check-in time",
        eventUserFullName: "Add user name here after adding cognito",
        eventUsername: "Add user name here after adding cognito",
      });
      eventCreated = true;
    } catch (e) {
      handleUserMessage(
        "danger",
        `Error: ${e.message}`,
        "If this continues, contact your systems administrator"
      );
    }

    if (eventCreated) {
      try {
        const { data } = await sembraTimeService.changeCheckinTime(values);

        // console.log("DATA: ", data);
        if (data.status === "Success") {
          setUpdatedData(data.userInfo);
          handleUserMessage("success", "Check-In Time Successfully Updated");
        } else {
          handleUserMessage("danger", data.message);
        }
      } catch (e) {
        handleUserMessage(
          "danger",
          `ERROR: ${e.message}`,
          "If this continues, contact your systems administrator"
        );
      }
    } else {
      handleUserMessage(
        "danger",
        `Error: Unable to create event`,
        "If this continues, contact your systems administrator"
      );
    }

    // actions.setSubmitting(false);
  };

  const handleNewUpdate = () => {
    setUpdatedData(null);
    setUserMessage({});
  };

  return (
    <section className="selected-option-content">
      <h3>Change Check-In Time</h3>
      {updatedData && Object.keys(updatedData).length > 0 ? (
        <>
          <UserMessage userMessage={userMessage} />
          <ResponseTable responseData={updatedData} />
          <Button onClick={() => handleNewUpdate()}>New Update</Button>
        </>
      ) : (
        <>
          <ChangeCheckinTimeForm
            handleFormikSubmit={handleFormikSubmit}
            handleUserMessage={handleUserMessage}
          />
          <UserMessage userMessage={userMessage} />
        </>
      )}
    </section>
  );
}
