import { Outlet } from "react-router-dom";
import SembraCareOptions from "./sembracare-options";

export default function SembraCareLayout() {
  return (
    <section className="dashboard sembracare">
      <h2>SembraCare Dashboard</h2>
      <SembraCareOptions />
      <section className="dashboard-content">
        <Outlet />
      </section>
    </section>
  );
}
