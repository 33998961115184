import React, { useState } from "react";

export default function DefaultDashBoard() {
  return (
    <div className="selected-option-content">
      <h3>Hello, Sembra Care User!</h3>
      <p>Choose a task from the options to get started</p>
    </div>
  );
}
