import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import useAutoLogout from "./autoLogout";
import authService from "../../services/authService";

export default function Auth({ children }) {
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(undefined);
  // Change this state based on our authentication logic

  const logoutTime = 900000; // 15 minutes (time in milliseconds)

  // const logoutTime = 10000; // 10 seconds (time in milliseconds)

  const autoLogout = useAutoLogout(logoutTime);

  useEffect(() => {
    // using setTimeout to simulate async (auth) event
    // setTimeout(() => {
    if (localStorage.getItem("token")) {
      setCurrentUser(localStorage.getItem("success"));
    } else {
      setCurrentUser(null);
    }
    // }, 300);
  }, []);

  if (currentUser === undefined) {
    return null;
  }

  if (!currentUser) {
    navigate("/");
  }

  if (autoLogout || !isLoggedIn) {
    authService.logout();
  }

  return children;
}
