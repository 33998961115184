// import { Link } from "react-router-dom";
import { Button, Form, InputGroup } from "react-bootstrap";
import { Formik } from "formik";
import { object, string } from "yup";
import { AiOutlineUser, AiOutlineExclamationCircle } from "react-icons/ai";
import { RiLockPasswordFill } from "react-icons/ri";

const LoginForm = ({ handleFormikSubmit, handleUserMessage }) => {
  const validationSchema = object().shape({
    email: string().required("Email is required"),
    password: string().required("Password is required"),
  });

  // const handleValidatations = (values) => {
  //   const errors = {};

  //   if (!values.email) {
  //     errors.email = "Email is required";
  //   }

  //   if (!values.password) {
  //     errors.password = "Password is required";
  //   }
  //   return errors;
  // };

  return (
    <section>
      <Formik
        // validationSchema={validationSchema}
        initialValues={{ email: "", password: "" }}
        validationSchema={validationSchema}
        // validate={(values) => handleValidatations(values)}
        onSubmit={(values, actions) => handleFormikSubmit(values, actions)}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form className="text-center" onSubmit={handleSubmit}>
            {console.log("ERRORS: ", errors)}
            <Form.Group className="mb-3 text-start" controlId="email">
              <Form.Label>Email</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <AiOutlineUser />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="example@email.com"
                  name="email"
                  onChange={(e) => {
                    handleChange(e);
                    handleUserMessage(null);
                  }}
                  value={values.email}
                  className={
                    errors.email && touched.email ? "input-error" : null
                  }
                />
                {errors.email && touched.email && (
                  <InputGroup.Text
                    className={errors.email ? "error-icon" : null}
                  >
                    <AiOutlineExclamationCircle />
                  </InputGroup.Text>
                )}
              </InputGroup>
              {errors.email && touched.email ? (
                <div className="err-alert">{errors.email}</div>
              ) : null}
            </Form.Group>
            <Form.Group className="mb-3 text-start" controlId="password">
              <Form.Label>Password</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <RiLockPasswordFill />
                </InputGroup.Text>
                <Form.Control
                  type="password"
                  placeholder="Enter Password"
                  name="password"
                  onChange={handleChange}
                  value={values.password}
                  className={
                    errors.password && touched.password ? "input-error" : null
                  }
                />
                {errors.password && touched.password && (
                  <InputGroup.Text
                    className={errors.password ? "error-icon" : null}
                  >
                    <AiOutlineExclamationCircle />
                  </InputGroup.Text>
                )}
              </InputGroup>
              {errors.password && touched.password ? (
                <div className="err-alert">{errors.password}</div>
              ) : null}
            </Form.Group>
            <Button disabled={isSubmitting ? true : false} type="submit">
              Login
            </Button>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default LoginForm;
