// import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate, useLocation, Link } from "react-router-dom";

export default function SembraTimeOptions() {
  const navigate = useNavigate();
  const location = useLocation();

  const handleSelection = (selection) => {
    navigate(`${selection}`);
  };

  const getActiveSelection = (selection) => {
    if (location.pathname.includes(selection)) {
      return "active";
    }
  };

  return (
    <div className="dashboard-options">
      {/* <Link
        active={getActiveSelection("reset-user") ? true : false}
        to="reset-user-password"
      >
        Reset User Password
      </Link> */}
      <Button
        variant=""
        className={getActiveSelection("reset-user")}
        onClick={() => handleSelection("reset-user-password")}
      >
        Reset User Password
      </Button>
      <Button
        variant=""
        className={getActiveSelection("search-shift")}
        onClick={() => handleSelection("search-shift")}
      >
        Search Shift
      </Button>
      <Button
        variant=""
        className={getActiveSelection("reset-shift")}
        onClick={() => handleSelection("reset-shift")}
      >
        Reset Shift
      </Button>
      <Button
        variant=""
        className={getActiveSelection("change-check-in-time")}
        onClick={() => handleSelection("change-check-in-time")}
      >
        Change Check-In Time
      </Button>
      <Button
        variant=""
        className={getActiveSelection("change-check-in-location")}
        onClick={() => handleSelection("change-check-in-location")}
      >
        Change Check-In Location
      </Button>
    </div>
  );
}
