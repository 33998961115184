// import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate, useLocation, Link } from "react-router-dom";

export default function SembraSchoolOptions() {
  const navigate = useNavigate();
  const location = useLocation();

  const handleSelection = (selection) => {
    navigate(`${selection}`);
  };

  const getActiveSelection = (selection) => {
    if (location.pathname.includes(selection)) {
      return "active";
    }
  };

  return (
    <div className="dashboard-options">
       <Button
        variant=""
        className={getActiveSelection("reset-user")}
        onClick={() => handleSelection("reset-user-password")}
      >
        Reset User Password
      </Button>
      <Button
        variant=""
        className={getActiveSelection("sembraSchool/check-user-permissions")}
        onClick={() => handleSelection("check-user-permissions")}
      >
        Check User Permissions
      </Button>
      <Button
        variant=""
        className={getActiveSelection("sembraSchool/change-user-permissions")}
        onClick={() => handleSelection("change-user-permissions")}
      >
        Change User Permissions
      </Button>
      <Button
        variant=""
        className={getActiveSelection("sembraSchool/change-user-group")}
        onClick={() => handleSelection("change-user-group")}
      >
        Change User Group
      </Button>
    </div>
  );
}
