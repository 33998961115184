import { useState } from "react";
import { Button } from "react-bootstrap";
import ResetUserPasswordForm from "../forms/common/reset-user-password-form";
import commonService from "../../services/commonService";
import UserMessage from "./userMessages";

export default function ResetUserPassword() {
  const [userMessage, setUserMessage] = useState({});
  const handleUserMessage = (type, message, message2) => {
    setUserMessage({
      ...userMessage,
      type: type,
      message: message,
      message2: message2,
    });
  };

  const [responseData, setResponseData] = useState();
  const handleFormikSubmit = async (values, actions) => {
    // console.log("VAULUES: ", values, "\n", "ACTIONS: ", actions);
    setUserMessage({});

    let eventCreated = false;
    try {
      await commonService.createEventLog({
        eventApplication: "Success",
        eventFamily: "Common",
        eventType: "Update user password",
        eventUserFullName: "Add user name here after adding cognito",
        eventUsername: "Add user name here after adding cognito",
      });
      eventCreated = true;
    } catch (e) {
      handleUserMessage(
        "danger",
        `Error: ${e.message}`,
        "If this continues, contact your systems administrator"
      );
    }

    if (eventCreated) {
      try {
        const { data } = await commonService.updateUserPassword(values);

        // console.log("DATA: ", data);

        if (data.status === "Success") {
          setResponseData(data);
          handleUserMessage("success", data.message);
        } else {
          handleUserMessage("danger", data.message);
        }
      } catch (e) {
        handleUserMessage(
          "danger",
          `ERROR: ${e.message}`,
          "If this continues, contact your systems administrator"
        );
      }
    }

    // actions.setSubmitting(false);
  };

  const handleNewUser = () => {
    setResponseData(null);
    setUserMessage({});
  };

  return (
    <section className="selected-option-content">
      <h3>Reset User Password</h3>
      {responseData ? (
        <>
          <UserMessage userMessage={userMessage} />
          <Button onClick={() => handleNewUser()}>New User</Button>
        </>
      ) : (
        <>
          <ResetUserPasswordForm
            handleFormikSubmit={handleFormikSubmit}
            handleUserMessage={handleUserMessage}
          />
          <UserMessage userMessage={userMessage} />
        </>
      )}
    </section>
  );
}
