import { Form, InputGroup, Alert } from "react-bootstrap";
import SubmitBtn from "../../../common/submitBtn";
import { Formik } from "formik";
import { object, string } from "yup";
import { AiOutlineUser } from "react-icons/ai";
import { AiOutlineExclamationCircle } from "react-icons/ai";

const CheckUserPermissionsForm = (props) => {
  const validationSchema = object().shape({
    username: string().required("Username is required"),
  });

  return (
    <section>
      <Formik
        initialValues={{ username: "" }}
        validationSchema={validationSchema}
        onSubmit={(values, actions) =>
          props.handleFormikSubmit(values, actions)
        }
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            {/* {console.log("IS SUBMITTING: ", isSubmitting)} */}
            <Form.Group className="mb-3 text-start" controlId="username">
              <Form.Label>SembraSchool User</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <AiOutlineUser />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Agency Code + Employee IVRID (ex :hch100137)"
                  name="username"
                  onChange={(e) => {
                    handleChange(e);
                    props.handleUserMessage({});
                  }}
                  value={values.username}
                  className={
                    errors.username && touched.username ? "input-error" : null
                  }
                />
                {errors.username && touched.username && (
                  <InputGroup.Text
                    className={errors.username ? "error-icon" : null}
                  >
                    <AiOutlineExclamationCircle />
                  </InputGroup.Text>
                )}
              </InputGroup>
              {errors.username && touched.username ? (
                <Alert variant="danger">{errors.username}</Alert>
              ) : null}
            </Form.Group>

            <SubmitBtn isSubmitting={isSubmitting} text={"Check Permissions"} />
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default CheckUserPermissionsForm;
