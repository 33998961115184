// import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate, useLocation, Link } from "react-router-dom";

export default function SembraNetOptions() {
  const navigate = useNavigate();
  const location = useLocation();

  const handleSelection = (selection) => {
    navigate(`${selection}`);
  };

  const getActiveSelection = (selection) => {
    if (location.pathname.includes(selection)) {
      return "active";
    }
  };

  return (
    <div className="dashboard-options">
      <Button
        variant=""
        className={getActiveSelection("reset-user")}
        onClick={() => handleSelection("reset-user-password")}
      >
        Reset User Password
      </Button>
      <Button
        variant=""
        className={getActiveSelection("sembraNet/assessment")}
        onClick={() => handleSelection("assessment")}
      >
        Assessment
      </Button>
      <Button
        variant=""
        className={getActiveSelection("sembraNet/search-rn-assignments")}
        onClick={() => handleSelection("search-rn-assignments")}
      >
        Search RN Assignments
      </Button>
    </div>
  );
}
