import { useState } from "react";
import { Button } from "react-bootstrap";
import ChangeUserPermissionsForm from "../../../forms/protectedRouteForms/sembraSchool/change-user-permissions-form";
import sembraSchoolService from "../../../../services/sembraSchoolService";
import commonService from "../../../../services/commonService";
import { formatDate } from "../../../../helperFunctions.js/formatDate";
import UserMessage from "../../../common/userMessages";
import ResponseTable from "../../../common/tables/responseTable";

export default function ChangeSembraSchoolUserPermissions() {
  const [userMessage, setUserMessage] = useState({});
  const handleUserMessage = (type, message, message2) => {
    setUserMessage({
      ...userMessage,
      type: type,
      message: message,
      message2: message2,
    });
  };

  const [updatedData, setUpdatedData] = useState({});
  const handleFormikSubmit = async (values, actions) => {
    let eventCreated = false;
    try {
      await commonService.createEventLog({
        eventApplication: "Success",
        eventFamily: "SembraSchool",
        eventType: "Change user permissions",
        eventUserFullName: "Add user name here after adding cognito",
        eventUsername: "Add user name here after adding cognito",
      });
      eventCreated = true;
    } catch (e) {
      handleUserMessage(
        "danger",
        `Error: ${e.message}`,
        "If this continues, contact your systems administrator"
      );
    }

    // Wrap submission in event success conditions
    if (eventCreated) {
      try {
        const { data } = await sembraSchoolService.changeUserPermissions(
          values
        );
        if (data.status === "Success") {
          const formattedDate = formatDate(data.userInfo.date);
          delete data.userInfo.date;
          setUpdatedData(data.userInfo);
          handleUserMessage(
            "success",
            "User Role Successfully Updated",
            formattedDate
          );
        } else {
          handleUserMessage("danger", data.message);
        }
      } catch (e) {
        handleUserMessage(
          "danger",
          `Error: ${e.message}`,
          "If this continues, contact your systems administrator"
        );
      }
    } else {
      handleUserMessage(
        "danger",
        `Error: Unable to create event`,
        "If this continues, contact your systems administrator"
      );
    }
  };

  const handleNewUserPermissions = () => {
    setUpdatedData({});
    setUserMessage({});
  };

  return (
    <section className="selected-option-content">
      <h3>Change User Permissions</h3>
      {Object.keys(updatedData).length > 0 ? (
        <>
          <UserMessage userMessage={userMessage} />
          <ResponseTable responseData={updatedData} />
          <Button onClick={() => handleNewUserPermissions()}>New User</Button>
        </>
      ) : (
        <>
          <ChangeUserPermissionsForm
            handleFormikSubmit={handleFormikSubmit}
            handleUserMessage={handleUserMessage}
          />
          <UserMessage userMessage={userMessage} />
        </>
      )}
    </section>
  );
}
