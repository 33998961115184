import { useState } from "react";
import { Button } from "react-bootstrap";
import CheckUserPermissionsForm from "../../../forms/protectedRouteForms/sembraSchool/check-user-permissions-form";
import checkUserPermissions from "../../../../services/sembraSchoolService";
import UserMessage from "../../../common/userMessages";
import ResponseTable from "../../../common/tables/responseTable";

export default function CheckSembraSchoolUserPermissions() {
  const [userMessage, setUserMessage] = useState({});
  const handleUserMessage = (type, message, message2) => {
    setUserMessage({
      ...userMessage,
      type: type,
      message: message,
      message2: message2,
    });
  };

  const [responseData, setResponseData] = useState({});
  const handleFormikSubmit = async (values, actions) => {
    try {
      const { data } = await checkUserPermissions.checkUserPermissions(
        values.username
      );

      // console.log("DATA: ", data);

      if (data.status === "Success") {
        setResponseData(data.userInfo);
        handleUserMessage("success", `Permissions for ${values.username}`);
      } else {
        handleUserMessage("danger", data.message);
      }
    } catch (e) {
      handleUserMessage(
        "danger",
        e.message,
        "If this continues, contact your systems administrator"
      );
    }
  };

  const handleNewPermissionsCheck = () => {
    setResponseData({});
    setUserMessage({});
  };

  return (
    <section className="selected-option-content">
      <h3>Check User Permissions</h3>
      {Object.keys(responseData).length > 0 ? (
        <>
          <UserMessage userMessage={userMessage} />
          <ResponseTable responseData={responseData} />
          <Button onClick={() => handleNewPermissionsCheck()}>
            New Search
          </Button>
        </>
      ) : (
        <>
          <CheckUserPermissionsForm
            handleFormikSubmit={handleFormikSubmit}
            handleUserMessage={handleUserMessage}
          />
          <UserMessage userMessage={userMessage} />
        </>
      )}
    </section>
  );
}
