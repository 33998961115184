// import { Link } from "react-router-dom";
import { Button, Form, InputGroup } from "react-bootstrap";
import { Formik } from "formik";
import { object, string } from "yup";
import { RiLockPasswordFill } from "react-icons/ri";
import { AiOutlineUser, AiOutlineExclamationCircle } from "react-icons/ai";

const ResetPasswordForm = ({ handleFormikSubmit }) => {
  const validationSchema = object().shape({
    email: string().required("Email is required"),
    newPassword: string().required("Password is required"),
    confirmPassword: string().required("Retype your new password to confirm"),
  });

  const handleValidatations = (values) => {
    const errors = {};
    if (
      values.newPassword &&
      values.confirmPassword &&
      values.newPassword !== values.confirmPassword
    ) {
      // errors.newPassword = "Passwords do not match";
      errors.confirmPassword = "Passwords do not match";
    }
    return errors;
  };

  return (
    <section>
      <Formik
        initialValues={{
          email: "",
          newPassword: "",
          confirmPassword: "",
        }}
        validationSchema={validationSchema}
        validate={(values) => handleValidatations(values)}
        onSubmit={(values, actions) => handleFormikSubmit(values, actions)}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form className="text-center" onSubmit={handleSubmit}>
            {console.log(
              "FORM STATE",
              "\n",
              "VALUES:",
              "\n",
              values,
              "ERRORS: ",
              errors
            )}
            <Form.Group className="mb-3 text-start" controlId="email">
              <Form.Label>Email</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <AiOutlineUser />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="example@email.com"
                  name="email"
                  onChange={handleChange}
                  value={values.email}
                  className={
                    errors.email && touched.email ? "input-error" : null
                  }
                />
                {errors.email && touched.email && (
                  <InputGroup.Text
                    className={errors.email ? "error-icon" : null}
                  >
                    <AiOutlineExclamationCircle />
                  </InputGroup.Text>
                )}
              </InputGroup>
              {errors.email && touched.email ? (
                <div className="err-alert">{errors.email}</div>
              ) : null}
            </Form.Group>
            <Form.Group className="mb-3 text-start" controlId="newPassword">
              <Form.Label>New Password</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <RiLockPasswordFill />
                </InputGroup.Text>
                <Form.Control
                  type="password"
                  placeholder="Enter New Password"
                  name="newPassword"
                  onChange={handleChange}
                  value={values.newPassword}
                  className={
                    errors.newPassword && touched.newPassword
                      ? "input-error"
                      : null
                  }
                />
                {errors.newPassword && touched.newPassword && (
                  <InputGroup.Text
                    className={errors.newPassword ? "error-icon" : null}
                  >
                    <AiOutlineExclamationCircle />
                  </InputGroup.Text>
                )}
              </InputGroup>
              {errors.newPassword && touched.newPassword ? (
                <div className="err-alert">{errors.newPassword}</div>
              ) : null}
            </Form.Group>
            <Form.Group className="mb-3 text-start" controlId="confirmPassword">
              <Form.Label>Confirm New Password</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <RiLockPasswordFill />
                </InputGroup.Text>
                <Form.Control
                  type="password"
                  placeholder="Confirm New Password"
                  name="confirmPassword"
                  onChange={handleChange}
                  value={values.confirmPassword}
                  className={
                    errors.confirmPassword && touched.confirmPassword
                      ? "input-error"
                      : null
                  }
                />
                {errors.confirmPassword && touched.confirmPassword && (
                  <InputGroup.Text
                    className={errors.confirmPassword ? "error-icon" : null}
                  >
                    <AiOutlineExclamationCircle />
                  </InputGroup.Text>
                )}
              </InputGroup>
              {errors.confirmPassword && touched.confirmPassword ? (
                <div className="err-alert">{errors.confirmPassword}</div>
              ) : null}
            </Form.Group>
            <Button disabled={isSubmitting ? true : false} type="submit">
              Reset Password
            </Button>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default ResetPasswordForm;
