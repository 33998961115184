import { useState, useEffect } from 'react';

const useAutoLogout = (logoutTime) => {
  const [logout, setLogout] = useState(false);

  useEffect(() => {
    let timeoutId;

    const resetTimer = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => setLogout(true), logoutTime);
    };

    const clearTimer = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };

    const events = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];
    const resetEvents = () => {
      events.forEach((event) => {
        window.addEventListener(event, resetTimer);
      });
    };

    resetTimer();
    resetEvents();

    return () => {
      clearTimer();
      events.forEach((event) => {
        window.removeEventListener(event, resetTimer);
      });
    };
  }, [logoutTime]);

  return logout;
};

export default useAutoLogout;