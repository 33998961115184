import { Outlet } from "react-router-dom";
import SembraNetOptions from "./sembranet-options";

export default function SembraNetLayout() {
  return (
    <section className="dashboard sembranet">
      <h2>SembraNet Dashboard</h2>
      <SembraNetOptions />
      <section className="dashboard-content">
        <Outlet />
      </section>
    </section>
  );
}
