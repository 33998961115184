import { useMemo, useEffect } from "react";
// Link to useTable tutorial
// https://www.youtube.com/watch?v=YwP4NAZGskg&list=PLC3y8-rFHvwgWTSrDiwmUsl4ZvipOw9Cz
import { useTable, useSortBy, useGlobalFilter } from "react-table";
import { Table } from "react-bootstrap";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { VscSmiley } from "react-icons/vsc";
import { GlobalFilter } from "../globalFilter";

export default function RnTable({ handleSelectedRn, tableData }) {
  const data = tableData;
  const COLUMNS = [
    {
      Header: "First Name",
      accessor: "firstName",
    },
    {
      Header: "Last Name",
      accessor: "lastName",
    },
    {
      Header: "Phone",
      accessor: "mobilePhone",
    },
    {
      Header: "ID",
      accessor: "id",
    },
  ];

  const colData = useMemo(() => COLUMNS, []);
  const initialState = { hiddenColumns: ["id"] };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns: colData,
      data: data,
      initialState,
    },
    useGlobalFilter,
    useSortBy
  );

  const { globalFilter } = state;

  const handleRowClick = (selectedRow) => {
    handleSelectedRn(selectedRow);
  };

  return (
    <>
      <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
      <Table striped bordered hover {...getTableProps}>
        <thead>
          {headerGroups.map((group, index) => (
            <tr key={index} {...group.getHeaderGroupProps()}>
              {group.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <AiFillCaretDown className="ms-2" />
                      ) : (
                        <AiFillCaretUp className="ms-2" />
                      )
                    ) : (
                      <VscSmiley className="ms-2" />
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {tableData.length === 0 ? (
          <p>Nope!</p>
        ) : (
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  onClick={() => handleRowClick(row)}
                  className="clickable-row"
                >
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        )}
      </Table>
    </>
  );
}
