import { useState } from "react";
import { Form, InputGroup, Alert } from "react-bootstrap";
import SubmitBtn from "../../../common/submitBtn";
import { Formik } from "formik";
import { object, string } from "yup";
import { AiOutlineUser } from "react-icons/ai";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { GrGroup } from "react-icons/gr";
import sembraSchoolService from "../../../../services/sembraSchoolService";

const SearchRnAssignmentsForm = ({ handleFormikSubmit, handleUserMessage }) => {
  const validationSchema = object().shape({
    rnLastName: string().required("RN Last name is required"),
    firstInitial: string().required("RN First name initial is required"),
  });

  //   const handleValidatations = (values) => {
  //     const errors = {};
  //     if (
  //       values.userGroup === "Select Agency Group" ||
  //       values.userGroup === "No Agency Information"
  //     ) {
  //       errors.userGroup = "Select a user group";
  //     }
  //     return errors;
  //   };

  // const handleRnSearch = () => {
  //   console.log("Handling RN Search");
  // };

  //   const handleAgencyGroups = async (username) => {
  //     try {
  //       const { data } = await sembraSchoolService.pullAgencyGroups(username);
  //       if (data.length > 0 && username.length >= 0) {
  //         setAgencyGroups(data[0].agencyGroups);
  //       } else {
  //         setAgencyGroups([]);
  //       }
  //     } catch (e) {
  //       handleUserMessage(
  //         "danger",
  //         `ERROR: ${e.message}`,
  //         "If this continues, contact your systems administrator"
  //       );
  //       setAgencyGroups([]);
  //     }
  //   };

  return (
    <section>
      <Formik
        initialValues={{ rnLastName: "", firstInitial: "" }}
        validationSchema={validationSchema}
        // validate={(values) => handleValidatations(values)}
        onSubmit={(values, actions) => handleFormikSubmit(values, actions)}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form className="mt-3" onSubmit={handleSubmit}>
            {/* {console.log(
              "Values:",
              values,
              "\n",
              "ERRORS",
              errors,
              "\n",
              "IS SUBMITTING:",
              isSubmitting
            )} */}

            <Form.Group className="mb-3 text-start" controlId="rnLastName">
              <Form.Label>RN Last Name</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <AiOutlineUser />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  //   placeholder="example: sss100137"
                  name="rnLastName"
                  onChange={(e) => {
                    handleChange(e);
                    handleUserMessage(null);
                    // handleRnSearch(e.target.value);
                  }}
                  value={values.rnLastName}
                  className={
                    errors.rnLastName && touched.rnLastName
                      ? "input-error"
                      : null
                  }
                />
                {errors.rnLastName && touched.rnLastName && (
                  <InputGroup.Text
                    className={errors.rnLastName ? "error-icon" : null}
                  >
                    <AiOutlineExclamationCircle />
                  </InputGroup.Text>
                )}
              </InputGroup>
              {errors.rnLastName && touched.rnLastName ? (
                <Alert variant="danger">{errors.rnLastName}</Alert>
              ) : null}
            </Form.Group>

            <Form.Group className="mb-3 text-start" controlId="firstInitial">
              <Form.Label>First Name Initial</Form.Label>
              <InputGroup>
                <InputGroup.Text>
                  <AiOutlineUser />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  //   placeholder="example: sss100137"
                  name="firstInitial"
                  onChange={(e) => {
                    handleChange(e);
                    // handleRnSearch(e.target.value);
                    handleUserMessage(null);
                  }}
                  value={values.firstInitial}
                  className={
                    errors.firstInitial && touched.firstInitial
                      ? "input-error"
                      : null
                  }
                />
                {errors.firstInitial && touched.firstInitial && (
                  <InputGroup.Text
                    className={errors.firstInitial ? "error-icon" : null}
                  >
                    <AiOutlineExclamationCircle />
                  </InputGroup.Text>
                )}
              </InputGroup>
              {errors.firstInitial && touched.firstInitial ? (
                <Alert variant="danger">{errors.firstInitial}</Alert>
              ) : null}
            </Form.Group>

            <SubmitBtn isSubmitting={isSubmitting} text={"Search RN"} />
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default SearchRnAssignmentsForm;
