import { Outlet } from "react-router-dom";
import SembraSchoolOptions from "./sembra-school-options";

export default function SembraSchoolLayout() {
  return (
    <section className="dashboard sembraschool">
      <h2>SembraSchool Dashboard</h2>
      <SembraSchoolOptions />
      <section className="dashboard-content">
        <Outlet />
      </section>
    </section>
  );
}
