import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import Auth from "./components/common/Auth";
import "./assets/styles/App.css"
// Common 
import DefaultDashboard from './components/pages/protectedRoutes/default-dashboard';
import ResetUserPassword from './components/common/ResetUserPassword';

// Layouts 
import RouteLayout from './components/layouts/RouteLayout';
import PortalLayout from './components/layouts/portalLayout';

// Pages 
import NotFound from './components/pages/publicRoutes/NotFound';
import Login from './components/pages/publicRoutes/Login'
import ResetPassword from './components/pages/publicRoutes/reset-password';
import Welcome from './components/pages/protectedRoutes/Welcome';

// Pages/Routes SembraTime
import SembraTimeLayout from './components/pages/protectedRoutes/SembraTime/SembraTime';
import SearchSembraTimeShift from "./components/pages/protectedRoutes/SembraTime/search-shift";
import ResetSembraTimeShift from './components/pages/protectedRoutes/SembraTime/reset-shift';
import ChangeCheckinTime from './components/pages/protectedRoutes/SembraTime/change-checkin-time';
import ChangeCheckinLocation from './components/pages/protectedRoutes/SembraTime/change-checkin-location';


// Pages/Routes SembraSchool
import SembraSchoolLayout from './components/pages/protectedRoutes/SembraSchool/SembraSchool';
import CheckSembraSchoolUserPermissions from './components/pages/protectedRoutes/SembraSchool/check-user-permissions';
import ChangeSembraSchoolUserPermissions from './components/pages/protectedRoutes/SembraSchool/change-user-permissions';
import ChangeSembraSchoolUserGroup from './components/pages/protectedRoutes/SembraSchool/change-user-group';

// Pages/Routes SembraNet
import SembraNetLayout from './components/pages/protectedRoutes/SembraNet/SembraNet';
import SembraNetAssessment from './components/pages/protectedRoutes/SembraNet/assesment';
import SearchRnAssignments from './components/pages/protectedRoutes/SembraNet/search-rn-assignments';

// Pages/Routes SembraCare
import SembraCareLayout from './components/pages/protectedRoutes/SembraCare/SembraCare';
import SembraCareBadgeAlterations from './components/pages/protectedRoutes/SembraCare/badge-alterations';
import SembraCareAddressCoordinates from './components/pages/protectedRoutes/SembraCare/address-coordinates';


function App() {
  const router = createBrowserRouter(createRoutesFromElements(
    // Route Layout Wraps Entire Application 
    <Route path="/" element={<RouteLayout />}>
      <Route index element={<Login />} />
      <Route path="reset-password" element={<ResetPassword />} />
      <Route path="welcome" element={<Auth><Welcome /></Auth>} />

      {/* Portal Layout Wraps Portal (Nav/Sidebar and Main Content)  */}
      <Route path="sembra-success-portal" element={<PortalLayout />}>

        {/* Sembra Time Layout - Wraps SembraTime Dashboard and Options */}
        <Route path="sembraTime" element={<Auth><SembraTimeLayout /></Auth>}>
          <Route index element={<DefaultDashboard />} />
          <Route path="reset-user-password" element={<ResetUserPassword />} />
          <Route path="search-shift" element={<SearchSembraTimeShift />} />
          <Route path="reset-shift" element={<ResetSembraTimeShift />} />
          <Route path="change-check-in-time" element={<ChangeCheckinTime />} />
          <Route path="change-check-in-location" element={<ChangeCheckinLocation />} />
        </Route>

        {/* Sembra School Layout - Wraps SembraSchool Dashboard and Options */}
        <Route path="sembraSchool" element={<Auth><SembraSchoolLayout /></Auth>}>
          <Route index element={<DefaultDashboard />} />
          <Route path="reset-user-password" element={<ResetUserPassword />} />
          <Route path="check-user-permissions" element={<CheckSembraSchoolUserPermissions />} />
          <Route path="change-user-permissions" element={<ChangeSembraSchoolUserPermissions />} />
          <Route path="change-user-group" element={<ChangeSembraSchoolUserGroup />} />
        </Route>

        {/* Sembra Net Layout - Wraps SembraNet Dashboard and Options */}
        <Route path="sembraNet" element={<Auth><SembraNetLayout /></Auth>} >
          <Route index element={<DefaultDashboard />} />
          <Route path="reset-user-password" element={<ResetUserPassword />} />
          <Route path="assessment" element={<SembraNetAssessment />} />
          <Route path="search-rn-assignments" element={<SearchRnAssignments />} />
        </Route>

        {/* Sembra Care Layout - Wraps SembraCare Dashboard and Options */}
        <Route path="sembraCare" element={<Auth><SembraCareLayout /></Auth>} >
          <Route index element={<DefaultDashboard />} />
          <Route path="badge-alterations" element={<SembraCareBadgeAlterations />} />
          <Route path="address-coordinates" element={<SembraCareAddressCoordinates />} />
        </Route>
      </Route>

      <Route path="*" element={<NotFound />} />
    </Route >
  ))


  return (
    <RouterProvider router={router} />
  );
}

export default App;
