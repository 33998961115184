import Logo from "../../assets/images/SembraCareLogo.png";
import { FiLogOut } from "react-icons/fi";
import auth from "../../services/authService"

export default function Header() {

  const handleLogout = () => {
    auth.logout();
  };

  return (
    <header>
      <img src={Logo} alt="SembraCare Logo" />
      <div><span>Logout: </span> <FiLogOut onClick={handleLogout} /></div>
    </header>
  );
}
