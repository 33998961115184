import { Outlet } from "react-router";
import SembraTimeOptions from "./sembratime-options";

export default function SembraTimeLayout() {
  return (
    <section className="dashboard sembratime">
      <h2>SembraTime Dashboard</h2>
      <SembraTimeOptions />
      <section className="dashboard-content">
        <Outlet />
      </section>
    </section>
  );
}
