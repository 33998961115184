import { Container, Table } from "react-bootstrap";

export default function ResponseTable(props) {
  return (
    <Container fluid className="response-data">
      <Table striped bordered className="mb-0">
        <tbody>
          {Object.keys(props.responseData).map((key, index) => (
            <tr key={index}>
              <td style={{ width: "40%" }}>
                <strong>
                  {key
                    .replace(/([a-z])([A-Z])/g, "$1 $2")
                    .split(" ")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")}
                  :
                </strong>
              </td>
              <td>{props.responseData[key]}</td>
            </tr>
          ))}
          {props.deviationList && (
            <tr>
              <td>
                <strong>Deviations:</strong>
              </td>
              <td>
                {props.deviationList.map((deviation, index) => (
                  <div key={index}>
                    {deviation}
                    <hr />
                  </div>
                ))}
              </td>
            </tr>
          )}
          {props.updatedLocation && (
            <tr>
              <td>
                <strong>Updated Location:</strong>
              </td>
              <td>
                <div>
                  Lattitude: {props.updatedLocation[0]}
                  <hr />
                  Longitude: {props.updatedLocation[1]}
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Container>
  );
}
