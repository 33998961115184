import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function Welcome() {
  const navigate = useNavigate();
  return (
    <main>
      <p>Welcome</p>
      <div>
        <Button onClick={() => navigate("/sembra-success-portal/sembraTime")}>
          SembraTime
        </Button>
        {/* <Button onClick={() => navigate("/sembra-success-portal/sembraNet")}>
          SembraNet
        </Button> */}
        <Button onClick={() => navigate("/sembra-success-portal/sembraSchool")}>
          SembraSchool
        </Button>
        {/* <Button onClick={() => navigate("/sembra-success-portal/sembraCare")}>
          SembraCare
        </Button> */}
      </div>
    </main>
  );
}
