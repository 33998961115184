export default function SembraCareAddressCoordinates() {
  return (
    <section className="selected-option-content">
      <h3>Address Coordinates</h3>
      <div>
        <p>Additional Address Coordinates content</p>
        <p>Additional Address Coordinates content</p>
        <p>Additional Address Coordinates content</p>
      </div>
    </section>
  );
}
