import http from "./httpService";
import apiUrl from "../config.json";
import headerService from "./headerService";

// AKA Check User Role
async function checkUserPermissions(username, eventInfo) {
  const apiEndpoint = apiUrl.apiUrl + "/sembra-success/checkPermissions"
  try {
    const response = await http.post(apiEndpoint, { username: username }, headerService.setHeader())
    return response
  } catch (e) {
    throw e;
  }
}

//  AKA Change User Role
async function changeUserPermissions(values) {
  const apiEndpoint = apiUrl.apiUrl + "/sembra-success/updateUserRole"
  const payload = {
    username: values.username.toLowerCase(),
    role: values.permission
  }
  try {
    const response = await http.post(apiEndpoint, payload, headerService.setHeader());
    return response;
  } catch (error) {
    throw error;
  }
}

async function updateUserGroup(values) {
  const apiEndpoint = apiUrl.apiUrl + "/sembra-success/updateUserGroup"
  const payload = { username: values.username, group: values.userGroup }
  try {
    const response = await http.post(apiEndpoint, payload, headerService.setHeader());
    return response.data;
  } catch (error) {
    throw error;
  }
}

function pullAgencyGroups(username) {
  const apiEndpoint = apiUrl.apiUrl + "/sembra-success/pullAgencyGroups"
  return http.post(apiEndpoint, { username: username }, headerService.setHeader())
}

export default {
  checkUserPermissions,
  changeUserPermissions,
  pullAgencyGroups,
  updateUserGroup,
}