import { useState } from "react";
import { Button } from "react-bootstrap";
import ChangeCheckinLocationForm from "../../../forms/protectedRouteForms/sembraTime/change-checkin-location-form";
import sembraTimeService from "../../../../services/sembraTimeService";
import commonService from "../../../../services/commonService";
import UserMessage from "../../../common/userMessages";
import ResponseTable from "../../../common/tables/responseTable";

export default function ChangeCheckinLocation() {
  const [userMessage, setUserMessage] = useState({});
  const handleUserMessage = (type, message, message2) => {
    setUserMessage({
      ...userMessage,
      type: type,
      message: message,
      message2: message2,
    });
  };

  const [updatedData, setUpdatedData] = useState({});
  const [updatedLocation, setUpdatedLocation] = useState([]);
  const handleFormikSubmit = async (values, actions) => {
    let eventCreated = false;
    try {
      await commonService.createEventLog({
        eventApplication: "Success",
        eventFamily: "SembraSchool",
        eventType: "Change check-in location",
        eventUserFullName: "Add user name here after adding cognito",
        eventUsername: "Add user name here after adding cognito",
      });
      eventCreated = true;
    } catch (e) {
      handleUserMessage(
        "danger",
        `Error: ${e.message}`,
        "If this continues, contact your systems administrator"
      );
    }

    if (eventCreated) {
      try {
        const { data } = await sembraTimeService.changeCheckinLocation(values);

        // console.log("DATA: ", data);

        if (data.status === "Success") {
          setUpdatedLocation(data.userInfo.updatedCheckInLocation);
          delete data.userInfo.updatedCheckInLocation;
          setUpdatedData(data.userInfo);
          handleUserMessage(
            "success",
            "Check-In Location Successfully Updated"
          );
        } else {
          handleUserMessage("danger", data.message);
        }
      } catch (e) {
        handleUserMessage(
          "danger",
          `ERROR: ${e.message}`,
          "If this continues, contact your systems administrator"
        );
      }
    } else {
      handleUserMessage(
        "danger",
        `Error: Unable to create event`,
        "If this continues, contact your systems administrator"
      );
    }
    // actions.setSubmitting(false);
  };

  const handleNewUpdate = () => {
    setUpdatedData({});
    setUserMessage({});
  };

  return (
    <section className="selected-option-content">
      <h3>Change Check-In Location</h3>
      {updatedData && Object.keys(updatedData).length > 0 ? (
        <>
          <UserMessage userMessage={userMessage} />
          <ResponseTable
            responseData={updatedData}
            updatedLocation={updatedLocation}
          />
          <Button onClick={() => handleNewUpdate()}>New Update</Button>
        </>
      ) : (
        <>
          <ChangeCheckinLocationForm
            handleFormikSubmit={handleFormikSubmit}
            handleUserMessage={handleUserMessage}
          />
          <UserMessage userMessage={userMessage} />
        </>
      )}
    </section>
  );
}
