import { useMemo, useEffect } from "react";
// Link to useTable tutorial
// https://www.youtube.com/watch?v=YwP4NAZGskg&list=PLC3y8-rFHvwgWTSrDiwmUsl4ZvipOw9Cz
import { useTable, useSortBy, useGlobalFilter } from "react-table";
import { Table } from "react-bootstrap";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { VscSmiley } from "react-icons/vsc";
import { GlobalFilter } from "../globalFilter";
import UserMessage from "../../userMessages";

export default function AssignmentTable({ tableData }) {
  console.log("Table Data: ", tableData);
  const data = tableData.original.assignments;
  console.log(data);
  const COLUMNS = [
    {
      Header: "Date",
      accessor: "date",
    },
    {
      Header: "Time",
      accessor: "time",
    },
    {
      Header: "Client Last Name",
      accessor: "clientLastName",
    },
    {
      Header: "Client First Name",
      accessor: "clientFirstName",
    },
    {
      Header: "ID",
      accessor: "id",
    },
  ];

  const colData = useMemo(() => COLUMNS, []);
  const initialState = { hiddenColumns: ["id"] };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns: colData,
      data: data,
      initialState,
    },
    useGlobalFilter,
    useSortBy
  );

  const { globalFilter } = state;

  return (
    <>
      {data.length === 0 ? (
        <UserMessage
          userMessage={{
            type: "danger",
            message: "These are not the Droids you're looking for",
          }}
        />
      ) : (
        <>
          {" "}
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          <Table striped bordered {...getTableProps}>
            <thead>
              {headerGroups.map((group, index) => (
                <tr key={index} {...group.getHeaderGroupProps()}>
                  {group.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <AiFillCaretDown className="ms-2" />
                          ) : (
                            <AiFillCaretUp className="ms-2" />
                          )
                        ) : (
                          <VscSmiley className="ms-2" />
                        )}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </>
      )}
    </>
  );
}
