import http from "./httpService";
import apiUrl from "../config.json";
import headerService from "./headerService";
import authService from "./authService";



async function updateUserPassword(values) {
  const apiEndpoint = apiUrl.apiUrl + "/sembra-success/resetPassword";


  const payload = {
    username: values.username.toLowerCase(),
    password: values.newPassword
  }

  try {
    const response = await http.post(apiEndpoint, payload, headerService.setHeader());
    return response;
  } catch (e) {
    if (e.response && e.response.data.message === 'Unable to find user') {
      return { data: e.response.data };
    } else {
      throw e;
    }
  }
}

async function createEventLog(eventInfo) {
  const apiEndpoint = apiUrl.apiUrl + "/sembra-success/createEventLog";
  const currentUser = authService.getCurrentUser();
  eventInfo.eventUserFullName = currentUser.name;
  eventInfo.eventUsername = currentUser.email;
  try {
    const response = await http.post(apiEndpoint, eventInfo, headerService.setHeader());
    return response;
  } catch (error) {
    throw error;
  }

}


export default { updateUserPassword, createEventLog }