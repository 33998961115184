import { Alert } from "react-bootstrap";

export default function UserMessage({userMessage}) {
  // console.log(props);
  return (
    <Alert
      variant={userMessage.type}
      show={userMessage.type ? true : false}
    >
      <p>{userMessage.message}</p>
      {userMessage.message2 && <p>{userMessage.message2}</p>}
    </Alert>
  );
}
